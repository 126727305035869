<template>
  <div class="columns">
      <div class="content body-content ">
          <div class="panel panel-body trix-output" v-html="htmlContent">

          </div>
      </div>
  </div>
</template>

<script>
    import {log, showDefaultServerErrorMessage} from "@/shared/utils";
    import {getFAQs} from "@/shared/dataservice";

    import {formatContentMixin} from "@/views/mixins/formatContent";

    export default {
        name: 'UserFAQs',
        mixins: [formatContentMixin],
        data() {
            return {
                htmlContent: ""
            }
        },
        async created() {
            let result = await this.loadFAQData();

            log(["Result of page data load", result]);

            if(result.status === 200){
                this.htmlContent = result.data.data.body;
            }
            else
            {
                showDefaultServerErrorMessage("Load FAQs");
            }
        },
        methods: {
            async loadFAQData(){
                log(["Loading FAQ data"]);
                return getFAQs();
            },
        },
    }
</script>
